import React from "react";
import "./SkillGroups.scss";
import { Skill, SkillGroup } from "react-profile-lib";
import { Naming } from "../../utils/Naming";
import ErrorText from "../ErrorText/ErrorText";
import SkillLevel from "../SkillLevel/SkillLevel";

interface SkillGroupsData {
  header?: string;
  skills: Skill[];
  list?: SkillGroup[];
}

/**
<div className="level">
  <div style={{ width: (skillMap.get(id)?.level ?? 0) + "%" }}></div>
</div>;
 */

function SkillGroups(data: SkillGroupsData) {
  const skillMap = new Map(data.skills?.map((i) => [i.id, i]));

  return data.skills && data.list ? (
    <div className="ui-cmp-skill-groups">
      <h2>{data.header ?? "Skills"}</h2>
      {data.list.map((group) => (
        <div key={Naming.key(group.name)} className="group">
          <h3>{group.name}</h3>

          <div className="skills">
            {group.skills.map((id) => (
              <div key={id} className="skill">
                <div className="title">
                  {skillMap.get(id)?.name ?? <ErrorText text={id}></ErrorText>}
                </div>
                {skillMap.get(id)?.level ? (
                  <SkillLevel level={skillMap.get(id)?.level}></SkillLevel>
                ) : (
                  false
                )}
              </div>
            ))}
          </div>
        </div>
      )) ?? false}
    </div>
  ) : (
    <div></div>
  );
}

export default SkillGroups;
