import { CV } from "react-profile-lib";

export class Api {
  public static profile: string;
  public static serverUrl =
    window.location.hostname == "localhost"
      ? "http://localhost:4000"
      : `${window.location.protocol}//${window.location.host}/${window.location.pathname}`;

  private static _cv?: CV;

  public static get cv() {
    if (!this._cv) {
      throw new Error("CV data not available");
    }
    return this._cv;
  }

  public static api = (path: string): string => {
    return this.serverUrl + "/api/v1/" + path;
  };

  public static asset = (path: string): string => {
    return this.serverUrl + `/api/v1/asset/${this.profile}/` + path;
  };

  public static load = async (id: string): Promise<CV> => {
    this.profile = id;
    const res = await fetch(this.api(`profile/${id}`), {});

    if (!res.ok) {
      throw new Error(res.statusText);
    }

    this._cv = (await res.json()) as CV;

    return this._cv;
  };
}
