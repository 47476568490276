import React from "react";
import logo from "./logo.svg";
import "./Contact.scss";
import { Api } from "../../Api";
import { Link } from "react-profile-lib";
import * as Icons from "react-icons/io5";
import * as LucideIcons from "react-icons/lu";

interface ContactData {
  links: Link[];
}

const icons = new Map([
  ["linkedin", "IoLogoLinkedin"],
  ["facebook", "IoLogoFacebook"],
  ["www", "IoPlanet"],
  ["mail", "IoMail"],
  ["phone", "IoCall"],
  ["radio", "LuRadioTower"],
]);

/* Your icon name from database data can now be passed as prop */
const DynamicFaIcon = ({ name }: { name: string }) => {
  let IconComponent = (Icons as any)[name];

  if (!IconComponent) {
    IconComponent = (LucideIcons as any)[name];
  }

  if (!IconComponent) {
    // Return a default one
    return <Icons.IoLink />;
  }

  return <IconComponent />;
};

function Contact(data: ContactData) {
  const links = data.links;

  const short = data.links.filter((l) => !l.text);
  const long = data.links.filter((l) => !!l.text);

  return (
    <div className="ui-cmp-contact">
      <h2>Contact</h2>

      {long.length > 0 ? (
        <div className="large">
          {long.map((link) => (
            <div key={link.id} className="link">
              <div className="icon">
                <DynamicFaIcon name={icons.get(link.type) ?? "link"} />
              </div>
              {link.url ? (
                <a href={link.url} target="_blank">
                  {link.text ?? link.url}
                </a>
              ) : (
                <div>{link.text}</div>
              )}
            </div>
          )) ?? false}
        </div>
      ) : (
        false
      )}

      {short.length > 0 ? (
        <div className="small">
          {short.map((link) => (
            <a key={link.id} href={link.url} className="icon" target="_blank">
              <DynamicFaIcon name={icons.get(link.type) ?? "link"} />
            </a>
          )) ?? false}
        </div>
      ) : (
        false
      )}
    </div>
  );
}

export default Contact;
