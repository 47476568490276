import React from "react";
import "./ErrorText.scss";

export interface ErrorTextData {
  text: string;
}

function ErrorText(data: ErrorTextData) {
  return <div className="ui-cmp-error-text">{data.text}</div>;
}

export default ErrorText;
