import "./SkillLevel.scss";

interface SkillLevelData {
  level?: number;
}

// const colors = ["#ffff00", "#ccff00", "#88ff00", "#44ff00", "#00ff00"];
const colors = ["#333333", "#333333", "#333333", "#333333", "#333333"];

function SkillLevel(data: SkillLevelData) {
  const level = Math.round((data.level ?? 100) / 20) - 1;
  const color = colors[level] ?? colors[4];

  return (
    <div className="ui-cmp-skill-level">
      <div
        style={level >= 0 ? { background: color ?? "unset" } : undefined}
      ></div>
      <div
        style={level >= 1 ? { background: color ?? "unset" } : undefined}
      ></div>
      <div
        style={level >= 2 ? { background: color ?? "unset" } : undefined}
      ></div>
      <div
        style={level >= 3 ? { background: color ?? "unset" } : undefined}
      ></div>
      <div
        style={level >= 4 ? { background: color ?? "unset" } : undefined}
      ></div>
    </div>
  );
}

export default SkillLevel;
