import React from "react";
import logo from "./logo.svg";
import "./Education.scss";
import { Api } from "../../Api";
import { Link, Organization } from "react-profile-lib";
import * as Icons from "react-icons/ti";
import { DateTime } from "../../utils/DateTime";

interface EducationData {
  list?: Organization[];
}

function Education(data: EducationData) {
  return data.list ? (
    <div className="ui-cmp-education">
      <h2>Education</h2>
      <div className="list">
        {data.list?.map((education) => (
          <div key={education.id} className="education">
            {education.logo ? (
              <img
                className="logo"
                alt={education.name + ", " + education.name + " logo"}
                src={Api.asset("company/" + education.logo)}
              ></img>
            ) : (
              false
            )}
            <div className="title">{education.title}</div>
            <div className="role">
              {education.roles?.length ? education.roles[0].name : false}
            </div>
            <div className="name">{education.name}</div>
            <div className="location">{education.location}</div>
            <div className="duration">
              <span>
                {education.start
                  ? DateTime.formatDateYear(education.start)
                  : false}
              </span>
              <span>
                {education.end ? DateTime.formatDateYear(education.end) : false}
              </span>
            </div>
          </div>
        )) ?? false}
      </div>
    </div>
  ) : null;
}

export default Education;
