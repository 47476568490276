import React from "react";
import "./Tag.scss";

interface TagData {
  error?: boolean;
  type: string;
  name: string;
}

function Tag(data: TagData) {
  return (
    <div
      className={["ui-tag", data.error ? "error" : undefined].join(" ")}
      data-type={data.type}
    >
      {data.name}
    </div>
  );
}

export default Tag;
