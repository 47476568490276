import React from "react";
import logo from "./logo.svg";
import "./Text.scss";
import { Api } from "../../Api";

interface TextData {
  section?: string;
  title?: string;
  text?: string;
}

function Text(data: TextData) {
  if (!data.text) {
    return <div>{false}</div>;
  }
  return (
    <div className={["ui-cmp-text", data.section].join(" ")}>
      <h2>{data.title ?? false}</h2>
      <p>{data.text}</p>
    </div>
  );
}

export default Text;
