import React from "react";
import "./App.scss";
import Photo from "./components/Photo/Photo";
import { Api } from "./Api";
import Text from "./components/Text/Text";
import Header from "./components/Header/Header";
import "@fontsource/roboto"; // Defaults to weight 400
// Supports weights 300-800
import "@fontsource-variable/open-sans";
import { useEffect } from "react";
import Separator from "./components/Separator/Separator";
import Contact from "./components/Contact/Contact";
import Education from "./components/Education/Education";
import Experience from "./components/Experience/Experience";
import Skills from "./components/Skills/Skills";
import SkillGroups from "./components/SkillGroups/SkillGroups";
import Projects from "./components/Projects/Projects";
import Recommendation from "./components/Recommendation/Recommendation";

function App() {
  useEffect(() => {
    document.title = "Florentin Szomoru";
  }, []);

  return (
    <div className="App">
      <Photo image={Api.cv.photo}></Photo>
      <Header
        title={Api.cv.title}
        first={Api.cv.first}
        last={Api.cv.last}
      ></Header>
      <Separator></Separator>
      <Contact links={Api.cv.contact ?? []}></Contact>
      <Text section="about-me" title={"About me"} text={Api.cv.about}></Text>
      <Separator></Separator>
      <div>
        <Education list={Api.cv.education}></Education>
        <SkillGroups
          list={Api.cv.skillGroups}
          skills={Api.cv.skills ?? []}
        ></SkillGroups>
      </div>
      <div>
        <Experience list={Api.cv.experience}></Experience>
        <Separator noPrint={true}></Separator>
        <Projects
          skills={Api.cv.skills ?? []}
          organizations={Api.cv.experience ?? []}
          list={Api.cv.projects}
        ></Projects>
        <Separator noPrint={true}></Separator>

        <Recommendation
          orgs={Api.cv.experience ?? []}
          list={Api.cv.referrals ?? []}
        ></Recommendation>
      </div>
      <Separator noPrint={true}></Separator>
    </div>
  );
}

export default App;
