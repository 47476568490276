import React from "react";
import logo from "./logo.svg";
import "./Recommendation.scss";
import { Api } from "../../Api";
import { Organization, Referral } from "react-profile-lib";
import { DateTime } from "../../utils/DateTime";
import TimeInterval from "../TimeInterval/TimeInterval";

interface RecommendationData {
  list?: Referral[];
  orgs: Organization[];
}

function Recommendation(data: RecommendationData) {
  if (!data.list?.length) {
    return <div>{false}</div>;
  }

  const org = new Map(data.orgs.map((o) => [o.id, o]));

  // {
  //   start: "Valentina-Mariana Boeru",
  //   role: "Senior Software Engineer",
  //   date: "July 25, 2016",
  //   relation: "Worked in the same group",
  //   experience: "oracle",
  //   url: "https://www.linkedin.com/in/florentinszomoru/details/recommendations/?detailScreenTabIndex=0",
  //   text: "Florentin is an extremely valuable person to work with. His responses are timely and he's open to working with new people. He helped me enlarge my technical skills while proving himself to be a true team player and assuring that the proposed deadlines are reached. He always come with new and innovative solutions. I highly recommend him!",
  // },

  return (
    <div className="ui-cmp-recommendations no-print">
      <h2>Recommendations</h2>
      {data.list.map((r) => (
        <div className="recommendation">
          <h3>{r.from}</h3>
          <a href={r.url} target="_blank">
            {" "}
            {[
              r.role,
              r.experience
                ? org.get(r.experience)?.name ?? r.experience
                : undefined,
            ].join(" | ") + " "}
            | <TimeInterval end={r.date}></TimeInterval>
          </a>
          <blockquote className="quote">{r.text}</blockquote>
        </div>
      ))}
    </div>
  );
}

export default Recommendation;
