import React from "react";
import logo from "./logo.svg";
import "./Header.scss";
import { Api } from "../../Api";

interface HeaderData {
  first: string;
  last: string;
  title: string;
}

function Header(data: HeaderData) {
  return (
    <div className="ui-cmp-header">
      <h1>
        {data.first}
        <br />
        <span className="last">{data.last}</span>
      </h1>
      <h2>{data.title}</h2>
    </div>
  );
}

export default Header;
