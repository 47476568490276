import React from "react";
import logo from "./logo.svg";
import "./Experience.scss";
import { Api } from "../../Api";
import { Link, Organization } from "react-profile-lib";
import { DateTime } from "../../utils/DateTime";
import { Naming } from "../../utils/Naming";
import TimeInterval from "../TimeInterval/TimeInterval";
import markdownit from "markdown-it";

const md = markdownit();

interface ExperienceData {
  list?: Organization[];
}

function Experience(data: ExperienceData) {
  return (
    <div className="ui-cmp-experience">
      <h2>Experience</h2>
      <div className="list">
        {data.list?.map((experience) => (
          <div key={experience.id} className="experience">
            <header>
              <h3 className="company">
                {experience.name}
                {experience.location ? (
                  <span className="location"></span>
                ) : (
                  false
                )}
              </h3>

              {experience.logo ? (
                <img
                  className="logo"
                  alt={experience.name + ", " + experience.name + " logo"}
                  src={Api.asset("company/" + experience.logo)}
                ></img>
              ) : (
                false
              )}

              {experience.title ? (
                <h4 className="title">{experience.title}</h4>
              ) : (
                false
              )}
            </header>

            <div className="roles">
              {experience.roles.map((role) => (
                <div
                  key={[
                    experience.id ?? Naming.key(experience.name),
                    role.id ?? Naming.key(role.name),
                  ].join("-")}
                  className="role"
                >
                  <h4 className="name">{role.name}</h4>
                  <div className="duration">
                    <TimeInterval
                      start={role.start}
                      end={role.end}
                    ></TimeInterval>
                  </div>
                  {role.description ? (
                    <p
                      className="desc"
                      dangerouslySetInnerHTML={{
                        __html: md.render(role.description),
                      }}
                    ></p>
                  ) : (
                    false
                  )}
                  {role.entries ? (
                    <div className="entries">
                      {role.entries.map((entry) => (
                        <div
                          key={[
                            experience.id,
                            role.id,
                            Naming.key(
                              typeof entry == "string" ? entry : entry.name
                            ),
                          ].join("-")}
                          className="entry"
                        >
                          {typeof entry == "string" ? (
                            <p className="name">{entry}</p>
                          ) : (
                            <p className="name">{entry.name}</p>
                          )}
                        </div>
                      ))}
                    </div>
                  ) : (
                    false
                  )}
                </div>
              ))}
            </div>
          </div>
        )) ?? false}
      </div>
    </div>
  );
}

export default Experience;
