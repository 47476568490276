import React from "react";
import logo from "./logo.svg";
import "./Projects.scss";
import { Api } from "../../Api";
import { Entry, Link, Organization, Skill } from "react-profile-lib";
import * as Icons from "react-icons/ti";
import { DateTime } from "../../utils/DateTime";
import TimeInterval from "../TimeInterval/TimeInterval";
import Tag from "../Tag/Tag";
import ErrorText from "../ErrorText/ErrorText";

interface ProjectsData {
  skills: Skill[];
  organizations: Organization[];
  list?: Entry[];
}

function Projects(data: ProjectsData) {
  const orgs = new Map(data.organizations.map((o) => [o.id, o]));
  const skill = new Map(data.skills.map((s) => [s.id, s]));

  return data.list ? (
    <div className="ui-cmp-projects no-print">
      <h2>Projects</h2>
      <div className="list">
        {data.list.map((project) => (
          <div key={project.id} className="project">
            <h3 className="name">{project.name}</h3>

            {project.description ? (
              <p className="name">{project.description}</p>
            ) : (
              false
            )}

            {project.org ? (
              <div className="organization">
                {orgs.get(project.org)?.name ?? (
                  <ErrorText text={project.org}></ErrorText>
                )}
              </div>
            ) : undefined}

            <div className="duration">
              <TimeInterval
                start={project.start}
                end={project.end}
              ></TimeInterval>
            </div>

            {project.skills ? (
              <div className="ui-tags">
                {project.skills.map((s) => (
                  <Tag
                    key={s}
                    error={skill.get(s)?.name == undefined}
                    name={skill.get(s)?.name ?? s}
                    type="skill"
                  ></Tag>
                ))}
              </div>
            ) : (
              false
            )}
          </div>
        )) ?? false}
      </div>
    </div>
  ) : null;
}

export default Projects;
