import React from "react";
import logo from "./logo.svg";
import "./Photo.scss";
import { Api } from "../../Api";

interface PhotoData {
  image?: string;
}

function Photo(data: PhotoData) {
  if (!data.image) {
    return <div>{false}</div>;
  }
  return (
    <div className="ui-cmp-photo">
      <div className="frame">
        <img src={Api.asset(data.image)} alt="Profile photo" />
      </div>
    </div>
  );
}

export default Photo;
