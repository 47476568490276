import React from "react";
import logo from "./logo.svg";
import "./Separator.scss";
import { Api } from "../../Api";

interface SeparatorData {
  noPrint?: boolean;
}

function Separator(data: SeparatorData) {
  return (
    <div
      className={[
        "ui-cmp-separator",
        data.noPrint ? "no-print" : undefined,
      ].join(" ")}
    ></div>
  );
}

export default Separator;
