import React from "react";
import "./TimeInterval.scss";
import { Api } from "../../Api";
import { Organization } from "react-profile-lib";
import { DateTime } from "../../utils/DateTime";
import { Naming } from "../../utils/Naming";

interface TimeIntervalData {
  start?: number | string;
  end?: number | string;
}

function TimeInterval(data: TimeIntervalData) {
  const start = data.start ? DateTime.formatDateYearMonth(data.start) : false;
  const end = data.end ? DateTime.formatDateYearMonth(data.end) : false;
  return (
    <span className="ui-cmp-time-interval">
      {start ? <span className="start">{start}</span> : undefined}
      {start && end ? <span> - </span> : undefined}
      {end ? <span className="start">{end ? end : "present"}</span> : undefined}
    </span>
  );
}

export default TimeInterval;
